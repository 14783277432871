import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];
  static values = {
    sourceSelector: String,
    visible: Array 
  }

  connect() {
    const controller = this;
    this.sourceField = document.querySelector(this.sourceSelectorValue);
    this.sourceField.addEventListener('change', (e) => controller.toggleElement());

    this.toggleElement();
  }

  toggleElement() {
    var hide = !this.visibleValue.includes(this.sourceField.value);
    this.element.classList.toggle('d-none', hide);
  }
}
